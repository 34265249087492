:root {
  --black-main: black;
  --color-heading-alternate: #5f5656;
  --color-main: #fca93a;
  --color-background: #fce7c7;
  --color-background--purple: #8e55735b;
  --font-deesign--logo: "Righteous";
  --font-mimoney--logo: "Matemasie";
  --font-heading-serif: "Abril Fatface";
  --font-content-sans: "Montserrat";
  --up-arrow-color: #fff;
  --up-arrow-shadow: 0px 0px 10px green;
  --down-arrow-color: #00000049;
  --down-arrow-shadow: 0px 0px 0px #fff0;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slide-side {
  100% {
    transform: translateX(100%);
  }

  0% {
    transform: translateX(0%);
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  60% {
    transform: translateY(-3%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
/*# sourceMappingURL=index.0f0aac26.css.map */
